@font-face {
  font-family: 'icons';
  src: url('../font/icons.eot?70390554');
  src: url('../font/icons.eot?70390554#iefix') format('embedded-opentype'),
       url('../font/icons.woff2?70390554') format('woff2'),
       url('../font/icons.woff?70390554') format('woff'),
       url('../font/icons.ttf?70390554') format('truetype'),
       url('../font/icons.svg?70390554#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons';
    src: url('../font/icons.svg?70390554#icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-heart-empty:before { content: '\e800'; } /* '' */
.icon-info-circled:before { content: '\e800'; } /* '' */
.icon-menu:before { content: '\e801'; } /* '' */
.icon-graduation-cap:before { content: '\e802'; } /* '' */
.icon-warning-empty:before { content: '\e802'; } /* '' */
.icon-calendar:before { content: '\e803'; } /* '' */
.icon-share:before { content: '\e804'; } /* '' */
.icon-attention:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-bell:before { content: '\e805'; } /* '' */
.icon-picture-outline:before { content: '\e806'; } /* '' */
.icon-cancel:before { content: '\e807'; } /* '' */
.icon-ticket:before { content: '\e808'; } /* '' */
.icon-back-in-time:before { content: '\e809'; } /* '' */
.icon-gauge:before { content: '\e80a'; } /* '' */
.icon-doc-text:before { content: '\e80b'; } /* '' */
.icon-gauge-1:before { content: '\e80c'; } /* '' */
.icon-clipboard:before { content: '\e80d'; } /* '' */
.icon-layers:before { content: '\e80e'; } /* '' */
.icon-off:before { content: '\e80f'; } /* '' */
.icon-home-outline:before { content: '\e810'; } /* '' */
.icon-print:before { content: '\e811'; } /* '' */
.icon-attention-alt:before { content: '\e812'; } /* '' */
.icon-user-outline:before { content: '\e813'; } /* '' */
.icon-power:before { content: '\e814'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */
.icon-upload:before { content: '\f02f'; } /* '' */
.icon-chat:before { content: '\f03d'; } /* '' */
.icon-info-circled-alt:before { content: '\f086'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-question-circle-o:before { content: '\f29c'; } /* '' */
